import React from "react"
import {Link} from "gatsby"
import styled from "styled-components";

import Layout from "../components/layout"
import SEO from "../components/seo"

import COLORS from "./../styles/colors";

import ImageXBg from "./../images/x-bg.webp";
import KidsImage from "./../images/steve-1.png";
import SingleGearImage from "./../images/single-gear.png";

import hb4545Image from "./../images/hb4545.png";
import Map from "../components/map";

const IntroContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    min-height: 500px;

    @media screen and (max-width: 800px){
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }
`;

const IntroPanel = styled.div`
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const IntroLeft = styled(IntroPanel)`
    background: linear-gradient(
      rgba(53, 110, 216, 0.8), 
      rgba(53, 110, 216, 0.8)
    ),
    url(${KidsImage});
    background-position: top center;
    background-size: cover;

    h1 {
        color: #fff;
        font-weight: 300;
        line-height: 2.5rem;
        font-size: 2.2rem;
    }
`;

const NeedsWrapper = styled.div`
    background-image: url(${ImageXBg});

    h1 {
        margin-top: 1rem;
    }
`;

const NeedsContainer = styled.div`
    max-width: 850px;
    margin: 0 auto;
    padding: 3rem 1rem;
`;

const NeedsList = styled.div`
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 0.5rem;
`;

const NeedsItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 0;
    img {
        width: 2rem;
        display: block;
        margin-right: 1rem;
    }
`;


const MapText = styled.div`
    max-width: 850px;
    margin: 0 auto;
    padding: 3rem 2rem 0;
    h1 {
        line-height: 2.5rem;
    }
`;

const MapContainer = styled.div`
    max-width: 800px;
    padding: 2rem 0;
    margin: 0 auto 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;


const PartnerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 400px;
`;

const PartnerPanel = styled.div`
    max-width: 800px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
   
`;

const StyledLink = styled(Link)`
    border: 2px solid ${COLORS.primaryColor};
    padding: 0.5rem 1rem;
    margin: 1rem;
    text-decoration: none;
    color: ${COLORS.primaryColor};
    transition: all  0.5s;
    display: inline-block;

    &:hover {
        background-color: ${COLORS.primaryColor};
        color: #fff;
    }
`;

const Corona = styled.div`
    background-color: ${COLORS.primaryColor};
    color: #fff;
`;
const CoronaContainer = styled.div`
    max-width: 950px;
    padding: 2rem 1rem 0;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    line-height: 1.5rem;
    align-items: center;

    @media screen and (max-width: 800px) {
        flex-direction: column;
        text-align: center;
        align-items: center;
        padding-bottom: 2rem
    }

    h2 {
        margin-top: -2rem;
        @media screen and (max-width: 800px) {
            margin-top: 0;
        }
    }

    img {
        padding-bottom: 2rem;
        padding-right: 2rem;
        display: block;
        max-width: 14rem;
        @media screen and (max-width: 800px) {
            padding-bottom: 1rem;
            padding-right: 0;
        }
    }
`;

// const WhiteLink = styled(Link)`
//     border: 2px solid #fff;
//     padding: 0.5rem 1rem;
//     margin: 1rem 1rem 2rem;
//     text-decoration: none;
//     color: #fff;
//     transition: all 0.5s;
//     display: inline-block;
//     &:visited {
//         color: #fff;
//     }
//     &:hover {
//         background-color: #fff;
//         color: ${COLORS.primaryColor};
//     }
// `;

const InstagramFeedContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem 3rem;
    text-align: center;
`;

const TwitterContainer = styled.div`
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
`;


const IndexPage = () => {

    return (
        <Layout>
            <SEO title="Home" />
            <IntroContainer>
                <IntroLeft>
                    <h1>Professional Development<br />Experts</h1>
                </IntroLeft>
                <IntroPanel style={{padding: "5%"}}>
                    <div>
                        <h1>Students. Strategies. Success</h1>
                        <p>High impact, learner-centered professional development!  Strategies, techniques and tools designed to move educational theory to practical application. That’s s3strategies!</p>
                        <StyledLink to="/expertise/">More About Us</StyledLink>
                    </div>
                </IntroPanel>
            </IntroContainer>
            <Corona>
                <CoronaContainer>
                    <img src={hb4545Image} alt="covid response" />
                    <div><h2>Texas!</h2>Ask us about our Student Asynchronous Content and Processing Skills Modules for acceleration and remediation as outlined in HB 4545.</div>
                </CoronaContainer>
            </Corona>
            <NeedsWrapper>
                <NeedsContainer>
                    <h1>We customize to your needs.</h1>
                    <p>s3strategies partners with you to build long-term relationships.  Professional learning should never be just one more thing added to an educator’s already full plate. We will:</p>
                    <NeedsList>
                        <NeedsItem><img src={SingleGearImage} alt="gear-icon" />align to your state standards</NeedsItem>
                        <NeedsItem><img src={SingleGearImage} alt="gear-icon" />tailor for content and/or grade level needs</NeedsItem>
                        <NeedsItem><img src={SingleGearImage} alt="gear-icon" />support your initiatives and objectives</NeedsItem>
                        <NeedsItem><img src={SingleGearImage} alt="gear-icon" />develop new workshops upon request</NeedsItem>
                    </NeedsList>
                    <div style={{textAlign: "center"}}>
                        <StyledLink to="/workshops/">Learn More</StyledLink>
                    </div>
                </NeedsContainer>
            </NeedsWrapper>
            <MapText>
                <h1>Over 15 years experience working with diverse districts throughout the U.S.</h1>
                <p>As s3strategies continues to grow, we remain committed to aligning to your state standards and your unique needs.</p>
            </MapText>
            <MapContainer>
                <Map />
            </MapContainer>
            <PartnerContainer>
                <PartnerPanel>
                    <div>
                        <h1>How can we help you?</h1>
                        <p>Consider s3strategies as you determine how to improve quality instruction and provide exceptional learning opportunities for your educators.  Equipping educators to empower their students is our passion!  What a privilege it is to work alongside you as you plan how to best support your educators.  We look forward to working with you!</p>
                        <StyledLink to="/contact/">Contact Us</StyledLink>
                    </div>
                </PartnerPanel>
            </PartnerContainer>
            <TwitterContainer>
                <h1>Twitter <a href="https://twitter.com/s3strategies" target="_blank" rel="noopener noreferrer" style={{textDecoration: "none"}}>@s3strategies</a></h1>
                <a className="twitter-timeline" data-height="1000" href="https://twitter.com/s3strategies">Loading...</a><script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
            </TwitterContainer>
        </Layout>
    );
}

export default IndexPage
